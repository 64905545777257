import { render, staticRenderFns } from "./aReputationGlobalRating.vue?vue&type=template&id=31c935e0&scoped=true"
import script from "./aReputationGlobalRating.vue?vue&type=script&lang=js"
export * from "./aReputationGlobalRating.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c935e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
