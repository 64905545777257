<template>
  <div class="a-reputation-answer-time-graph">
    <div class="d-flex justify-center data-info">
      <div class="text-center black--text">
        <v-icon class="title d-block mb-3" color="grey lighten-1"
          >mdi-send-clock-outline</v-icon
        >
        <span
          class="text-h4 d-block black--text font-weight-regular mb-1"
          style="font-family: 'Roboto Condensed' !important;"
          v-if="bi_report.report.time_to_answer >= 0"
          >{{ getKpiValue("time_to_answer") }}
          <span class="text-h6">{{ $t("days") }}</span></span
        >
        <span
          class="text-h4 d-block black--text font-weight-regular mb-1"
          style="font-family: 'Roboto Condensed' !important;"
          v-else
          ><v-icon>mdi-infinity</v-icon></span
        >
        <a-increment
          :value="bi_report.data_to_compare.time_to_answer"
          :kpi="'time_to_answer'"
          :increment="bi_report.increments.time_to_answer"
          :increment_type="'percent'"
        ></a-increment>
      </div>
    </div>
    <zingchart
      :height="188"
      :width="width"
      :data="graph_settings"
      :series="graph_series"
      class="graph"
    ></zingchart>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import aIncrement from "@/components/aIncrement";
import * as Utils from "@/utils.js";

export default {
  name: "a-reputation-answer-time-graph",
  components: {
    aIncrement
  },
  props: {
    height: {
      type: [String, Number],
      default: "170"
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    kpi: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    increment: {
      type: [String, Number],
      default: null
    },
    vs_value: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  data: vm => ({}),
  methods: {
    getKpiValue(kpi) {
      return Utils.format_kpi_value(
        this.bi_report.report[kpi],
        this.chain.kpis[kpi].data_format,
        this.l
      );
    }
  },
  computed: {
    graph_settings() {
      var tooltip = this.chart_settings.tooltip;
      tooltip["align"] = "center";
      tooltip["text"] =
        "<span style='font-family:\"Roboto Condensed\";font-size: 20px; color: %backgroundcolor;'>%v%</span><br><span style='font-size: 13px;'>%t</span>";
      return {
        type: "ring",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "transparent",
        plot: {
          detach: false,
          pieTransform: "flow=99",
          valueBox: {
            visible: false
          },
          animation: {
            delay: 0,
            effect: "ANIMATION_EXPAND_VERTICAL",
            method: "ANIMATION_LINEAR",
            sequence: "ANIMATION_BY_PLOT",
            speed: "600"
          },
          refAngle: 270,
          slice: 74
        },
        plotarea: {
          margin: "0px 0px 0px 0px"
        },
        tooltip: tooltip
      };
    },
    graph_series() {
      var series = [];
      for (var time_to_answer of this.times_to_answer) {
        series.push({
          values: [
            this.bi_reputation_answered_by_time.data[
              time_to_answer.code + "_avg"
            ]
          ],
          backgroundColor: time_to_answer.color_hexa,
          text:
            this.$t("answers") +
            " " +
            this.$t(time_to_answer.literal).toLowerCase()
        });
      }
      return series;
    },

    ...mapState({
      chain: state => state.chain.chain,
      times_to_answer: state => state.times_to_answer,
      chart_settings: state => state.chart_settings,
      bi_report: state => state.bi.report,
      bi_reputation_answered_by_time: state =>
        state.bi.reputation_answered_by_time
    }),
    ...mapGetters({
      l: "current_hotel_language"
    })
  }
};
</script>

<style scoped>
.a-reputation-answer-time-graph {
  position: relative;
}
.data-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.graph {
  position: relative;
  margin: -10px 0;
}
</style>
