import HotelService from "@/services/HotelService.js";
import router from "@/router";
import SegmentsChannelsService from "@/services/SegmentsChannelsService";
//import UserService from "@/services/UserService";
//import { getAncestorsNames } from "@/utils";

export const state = {
  hotels: [],
  show_hotel_list: false,
  hotel_available_months: {},
  hotel_available_months_data: {},
  hotel_available_years_data: {},
  hotel_available_months_data_vs: {},
  hotel_available_years_data_vs: {},
  no_hotels: false,
  all_hotels: [],
  hotel_currency_conversion_periods: [],
  siteminder_hotels: []
};

export const mutations = {
  SET_CURRENT(state, hotel) {
    state.hotel = hotel;
  },
  SET_HOTELS(state, hotels) {
    state.hotels = hotels;
    state.no_hotels = hotels.length == 0;
  },
  SET_SHOW_HOTEL_LIST(state, v) {
    state.show_hotel_list = v;
  },
  SET_HOTEL_AVAILABLE_MONTHS(state, v) {
    state.hotel_available_months = v.available_months;
    state.hotel_available_months_data = v.months;
    state.hotel_available_years_data = v.years;
    state.hotel_available_months_data_vs = v.months_to_compare;
    state.hotel_available_years_data_vs = v.years_to_compare;
  },
  SET_ALL_HOTELS(state, hotels) {
    state.all_hotels = hotels;
  },
  SET_HOTEL_CURRENCY_CONVERSION_PERIODS(state, periods) {
    state.hotel_currency_conversion_periods = periods;
  },
  SET_SITEMINDER_HOTELS(state, hotels) {
    state.siteminder_hotels = hotels;
  }
};

export const actions = {
  fetchHotels({ commit }) {
    HotelService.getHotels()
      .then(response => {
        commit("SET_HOTELS", Object.values(response.data));

        // SET DEFAULT
        commit("SET_CURRENT", state.hotels[0]);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  setShowHotelList({ commit }, v) {
    commit("SET_SHOW_HOTEL_LIST", v);
  },
  setCurrentHotel({ rootState }, hotel) {
    var params = rootState.route.params;
    params.hotel_slug = hotel.settings.slug;
    router.push({
      name: rootState.route.name,
      params: params,
      query: rootState.query
    });
  },
  setCurrentHotelById({ getters, rootState }, hotel_id) {
    const hotel = getters.getHotelById(hotel_id);
    var params = rootState.route.params;
    params.hotel_slug = hotel.settings.slug;
    router.push({
      name: rootState.route.name,
      params: params,
      query: rootState.query
    });
  },
  getAvailableMonths({ commit, rootState, rootGetters }) {
    // Check required
    if (!rootGetters.current_hotel) {
      return;
    }
    HotelService.getAvailableMonths(
      rootGetters.current_hotel.id,
      rootGetters.current_section,
      rootState.vs_filter_selected_option,
      rootState.vs_hotel_selected
    )
      .then(response => {
        commit("SET_HOTEL_AVAILABLE_MONTHS", response.data);
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  fetchAllHotels({ commit }) {
    HotelService.getAllHotels()
      .then(response => {
        commit("SET_ALL_HOTELS", Object.values(response.data.hotels));
      })
      .catch(error => {
        // console.log("There was an error:", error.response);
      });
  },
  updateHotel({ commit }, hotel) {
    return HotelService.modifyHotel(hotel).then(response => {
      commit("SET_ALL_HOTELS", Object.values(response.data.hotels));
    });
  },
  activateHotel({ commit }, hotel_id) {
    return HotelService.activateHotel(hotel_id).then(response => {
      commit("SET_ALL_HOTELS", Object.values(response.data.hotels));
    });
  },
  deactivateHotel({ commit }, hotel_id) {
    return HotelService.deactivateHotel(hotel_id).then(response => {
      commit("SET_ALL_HOTELS", Object.values(response.data.hotels));
    });
  },
  getCurrencyConversionPeriodsHotel({ commit }, hotel_id) {
    HotelService.getCurrencyConversionPeriodsHotel(hotel_id).then(response => {
      commit("SET_HOTEL_CURRENCY_CONVERSION_PERIODS", response.data);
    });
  },
  setCurrencyConversionPeriodsHotel({ commit }, data) {
    HotelService.setCurrencyConversionPeriodsHotel(data).then(response => {
      commit("SET_HOTEL_CURRENCY_CONVERSION_PERIODS", response.data);
    });
  },
  getSiteminderHotels({ commit }) {
    HotelService.getSiteminderHotels().then(response => {
      commit("SET_SITEMINDER_HOTELS", response.data);
    });
  }
};

export const getters = {
  // Only id is a parameter, state is by default
  getHotelById: state => id => {
    return state.hotels.find(hotel => hotel.id === id);
  },
  getHotelIndexById: state => id => {
    return state.hotels.findIndex(hotel => hotel.id === id);
  },
  getHotelBySlug: state => slug => {
    return state.hotels.find(hotel => hotel.settings.slug === slug);
  },
  getHotelAvailableYears: state => {
    return Object.keys(state.hotel_available_months);
  },
  getAncestorsNames: (state, getters) => hotel => {
    if (hotel.father_id && hotel.father_id !== 1) {
      const father = state.hotels.find(h => h.id === hotel.father_id);
      return father
        ? father.name + " " + getters.getAncestorsNames(father)
        : "";
    }
    return "";
  },
  getHotelsSearchKeys: (state, getters) => {
    return state.hotels.map(hotel => ({
      id: hotel.id,
      name: hotel.name,
      key: [
        hotel.name,
        getters.getAncestorsNames(hotel),
        hotel.location && hotel.location.city ? hotel.location.city : ""
      ]
        .join(" ")
        .toLowerCase()
    }));
  }
};
