import { render, staticRenderFns } from "./autocompleteRates.vue?vue&type=template&id=638a8008&scoped=true"
import script from "./autocompleteRates.vue?vue&type=script&lang=js"
export * from "./autocompleteRates.vue?vue&type=script&lang=js"
import style0 from "./autocompleteRates.vue?vue&type=style&index=0&id=638a8008&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638a8008",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VChip,VIcon,VListItemContent,VListItemIcon})
