<template>
  <v-card color="grey lighten-4 mb-2">
    <v-card-text class="white pa-0">
      <div class="grid-container" id="rms-year-sales-table">
        <table v-if="group_of_columns">
          <thead>
            <tr class="group-row">
              <th
                v-for="group in group_names"
                :key="group"
                :colspan="group_of_columns[group].length"
                :class="[
                  {
                    'sticky-columns-shadow': group === last_sticky_group_column,
                    'br-bolder': group !== last_sticky_group_column
                  }
                ]"
              >
                <template v-if="group">
                  <div class="column-group">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            group.toUpperCase() !== "BUD"
                              ? group.toUpperCase()
                              : "BUDGET"
                          }}
                        </span>
                        <template
                          v-if="group !== 'otb' && group !== 'forecast'"
                        >
                          <span>
                            vs OTB
                          </span>
                        </template>
                        <template v-if="group === 'forecast'">
                          <span>
                            vs BUDGET
                          </span>
                        </template>
                      </template>
                      <span>{{ $t(group) }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </th>
            </tr>
            <tr class="header-row">
              <th
                v-for="column_header in column_headers"
                :key="`${column_header.group}-${column_header.column}`"
                :class="[
                  {
                    'sticky-columns-shadow':
                      column_header.column === last_sticky_group_column,
                    'br-bolder':
                      last_column_of_each_group[column_header.group] ===
                        column_header.column &&
                      column_header.group !== last_sticky_group_column
                  }
                ]"
                :style="{
                  width: column_header.column == 'date' ? '200px' : '100px'
                }"
              >
                <div
                  class="cell"
                  :class="{
                    'kpi-row': !sticky_columns.includes(column_header.column),
                    'kpi-hovered':
                      kpi_hover.column === column_header.column &&
                      kpi_hover.group === column_header.group
                  }"
                  :style="{
                    width: column_header.column == 'date' ? '200px' : '100px'
                  }"
                >
                  <span
                    class="d-block day-name grey--text text--darken-3 text-uppercase"
                  >
                    {{ $t(column_header.column) }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="row in rows">
              <aRmsYearSalesTableRow
                :key="row.label"
                class="row-data"
                :label="row.label"
                :row="monthsValues[row.id]"
                :nonStickyColumnHeaders="non_sticky_column_headers"
                :lastStickyColumn="last_sticky_column"
                :configYearSalesTable="configYearSalesTable"
                :lastColumnOfEachGroup="last_column_of_each_group"
                @hoveredKpi="kpi_hover = $event"
                v-if="row.type === 'month'"
                :kpiMode="kpiMode"
              />
              <aRmsYearSalesTableRow
                :key="row.label"
                class="row-data quarter"
                :label="row.label"
                :row="quartersValues[row.id]"
                :nonStickyColumnHeaders="non_sticky_column_headers"
                :lastStickyColumn="last_sticky_column"
                :configYearSalesTable="configYearSalesTable"
                :lastColumnOfEachGroup="last_column_of_each_group"
                @hoveredKpi="kpi_hover = $event"
                v-if="row.type === 'quarter'"
                :kpiMode="kpiMode"
              />
            </template>
            <aRmsYearSalesTableRow
              class="row-data summary"
              :date="null"
              :row="yearValues"
              :label="$t('total')"
              :nonStickyColumnHeaders="non_sticky_column_headers"
              :lastStickyColumn="last_sticky_column"
              :configYearSalesTable="configYearSalesTable"
              :lastColumnOfEachGroup="last_column_of_each_group"
              isSummary
              @hoveredKpi="kpi_hover = $event"
              :kpiMode="kpiMode"
            />
          </tbody>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { showError } from "@/utils.js";
import aRmsYearSalesTableRow from "@/components/rms/clicktotravel/aRmsYearSalesTableRow";

let default_sticky_group_columns = [""];

export default {
  name: "a-rms-year-sales-table",
  components: {
    aRmsYearSalesTableRow
  },
  props: {
    configYearSalesTable: Object,
    monthsValues: Object,
    quartersValues: Object,
    yearValues: Object,
    kpiMode: String
  },
  emits: ["getDetails"],
  data: () => ({
    table_class: "rms-year-sales-table",
    kpi_hover: ""
  }),
  methods: {
    getDetails(v) {
      this.$emit("getDetails", v);
    },
    getHotelComparativeDates() {
      this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: Vue.moment(this.date)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.date)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          showError(this, "something_went_wrong");
        });
    }
  },
  computed: {
    rows() {
      const monthsKeys = Object.keys(this.monthsValues);
      const quartersKeys = Object.keys(this.quartersValues);

      const rows = [];
      for (let i = 1; i <= monthsKeys.length; i++) {
        rows.push({
          id: monthsKeys[i - 1],
          label: Vue.moment(`${monthsKeys[i - 1]}`).format("MMMM"),
          type: "month"
        });
        if (i % 3 === 0) {
          rows.push({
            id: quartersKeys[i / 3 - 1],
            label: quartersKeys[i / 3 - 1],
            type: "quarter"
          });
        }
      }
      return rows;
    },
    group_names() {
      return (
        this.configYearSalesTable && Object.keys(this.configYearSalesTable)
      );
    },
    group_of_columns() {
      return (
        (this.configYearSalesTable &&
          Object.keys(this.configYearSalesTable).reduce((prev, curr) => {
            prev[curr] = Object.keys(this.configYearSalesTable[curr].columns);
            return prev;
          }, {})) ||
        undefined
      );
    },
    column_headers() {
      const groups_and_columns = [];
      if (this.group_of_columns) {
        for (const group in this.group_of_columns) {
          this.group_of_columns[group].forEach(column => {
            groups_and_columns.push({ group, column });
          });
        }
      }
      return groups_and_columns;
    },
    last_column_of_each_group() {
      const column_headers = [...this.column_headers].reverse();
      return column_headers.reduce((prev, curr) => {
        if (!prev[curr.group]) {
          prev[curr.group] = curr.column;
        }
        return prev;
      }, {});
    },
    non_sticky_column_headers() {
      return (
        this.column_headers &&
        this.column_headers.filter(
          column =>
            !this.sticky_columns.find(
              sticky_column =>
                column.group === sticky_column.group &&
                column.column === sticky_column.column
            )
        )
      );
    },
    sticky_group_columns() {
      return default_sticky_group_columns;
    },
    last_sticky_group_column() {
      return this.sticky_group_columns[this.sticky_group_columns.length - 1];
    },
    sticky_columns() {
      return (
        this.column_headers &&
        this.column_headers.filter(column =>
          this.group_of_columns[this.last_sticky_group_column].includes(
            column.column
          )
        )
      );
    },
    last_sticky_column() {
      return this.sticky_columns[this.sticky_columns.length - 1];
    },
    non_sticky_group_columns() {
      return this.group_columns.filter(
        group => !this.sticky_group_columns.includes(group)
      );
    },
    // returns an object of year-month: [days]
    unique_years_months() {
      return this.days.reduce((prev, curr) => {
        const year_month = curr
          .split("-")
          .slice(0, 2)
          .join("-");
        if (!prev[year_month]) {
          prev[year_month] = [];
        }
        prev[year_month].push(curr);
        return prev;
      }, {});
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    this.getHotelComparativeDates();
  }
};
</script>

<style lang="scss" scoped>
.grid-container {
  overflow: auto;
  max-width: 100%;
  max-height: calc(100vh - 150px);
  .sticky-columns-shadow {
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15),
      2px 0px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.08);
  }
  .cell {
    height: $minHeightColRow;
    // border-top: $borderType $borderColorV;
    border-left: $borderType $borderColorV;
    border-right: 3px solid transparent;
    border-bottom: $borderType $borderColorV;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    min-width: 100%;
    text-wrap: wrap;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    &.kpi-row {
      border-right: $borderType $borderColorV;
      border-bottom: 3px solid transparent;
    }
    &.kpi-hovered {
      border-bottom: 3px solid #2d6ece;
    }
  }
  table {
    border-spacing: 0;
  }
  th {
    border: none;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColorV;
    width: 100px;
    min-width: 100px;
    position: sticky;
    top: 0;
    background: white;
    color: #a0a0a0;
    font-weight: normal;
    height: 44px;
    z-index: 1;
    &:nth-child(1) {
      position: sticky;
      left: 0;
      background: white;
      color: #e0e0e0;
      font-weight: normal;
      z-index: 2;
    }
  }
  .header-row {
    th {
      border-right: none;
      border-bottom: none;
      top: 44px;
      box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 1px 0px 0px rgba(0, 0, 0, 0.15), 2px 0px 0px 0px rgba(0, 0, 0, 0.11),
        0px 2px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.07),
        0px 4px 0px 0px rgba(0, 0, 0, 0.08);
      &:nth-child(1) {
        z-index: 2;
        border-bottom: $borderType $borderColorV;
      }
    }
  }
  td {
    border: none;
    width: 100px;
    min-width: 100px;
    background-color: white;
    height: 44px;

    &:nth-child(1) {
      position: sticky;
      left: 0;
      background: white;
      color: #e0e0e0;
      font-weight: normal;
    }
    .cell {
      &:hover {
        background-color: #f5fdff;
      }
    }
  }
  tr.row-data {
    td.day-data {
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 200px;
      text-transform: uppercase;
      cursor: pointer;
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 3px;
        height: calc(100% - 8px);
        width: 4px;
        background-color: grey;
        z-index: 10;
        border-radius: 5px;
        margin: 4px 0;
      }
      &.positive::after {
        background-color: rgb(23, 171, 112);
      }
      &.negative::after {
        background-color: rgb(172, 0, 0);
      }
      &.neutral::after {
        background-color: orange;
      }
    }
    &:hover {
      td.sticky-columns-shadow {
        .cell {
          border-right: 3px solid #2d6ece;
        }
      }
    }
    &:last-child {
      position: sticky;
      bottom: 0;
      z-index: 2;
    }
    &.quarter {
      td {
        background-color: #f5f5f5;
      }
    }
  }
  .column-group {
    padding: 3px 0;
    background-color: #1576c7;
    color: white;
    border-radius: 25px;
    margin: 0 1px;
  }
  .br-bolder {
    border-right: 2px solid #ccc !important;
  }
}
</style>
