<template>
  <v-card color="grey lighten-4" outlined>
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ $t("comparative_dates") }}
    </v-card-title>
    <v-card-text class="pa-0 white">
      <v-row class="events-day">
        <v-col cols="4">
          <v-card flat>
            <v-card-text class="pt-0">
              <v-card-subtitle
                class="font-weight-bold text-uppercase grey--text pl-0 pb-0"
              >
                {{ $t("this_year") }}
              </v-card-subtitle>
              <v-card-title
                class="font-weight-bold text-uppercase pl-0 pb-2 pt-0"
              >
                {{ getFriendlyDate(item_day.date) }}
              </v-card-title>
              <div v-for="event in item_day.events" :key="event.id">
                <v-chip x-small text-color="white" :color="eventColor(event)">
                  <v-icon x-small class="mr-1">
                    {{ getEventTypeIcon(event.type) }}
                  </v-icon>
                  <b>{{ event.name }}</b>
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-card-text class="pt-0">
              <v-card-subtitle
                class="font-weight-bold text-uppercase grey--text pl-0 pb-0"
              >
                {{ $t("ly") }}
              </v-card-subtitle>
              <v-card-title
                class="font-weight-bold text-uppercase pl-0 pb-2 pt-0"
              >
                {{ getFriendlyDate(item_day.date_vs) }}
              </v-card-title>
              <div>
                <v-chip
                  v-for="event in item_day.events_vs"
                  :key="event.id"
                  x-small
                  text-color="white"
                  :color="eventColor(event)"
                  class="mr-1"
                >
                  <v-icon x-small class="mr-1">
                    {{ getEventTypeIcon(event.type) }}
                  </v-icon>
                  <b>{{ event.name }}</b>
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-card-text class="pt-0">
              <v-card-subtitle
                class="font-weight-bold text-uppercase grey--text pl-0 pb-0"
              >
                {{ $t("lyn") }}
              </v-card-subtitle>
              <v-card-title
                class="font-weight-bold text-uppercase pl-0 pb-2 pt-0"
              >
                {{ date_lyn }}
              </v-card-title>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "a-rms-day-general-events",
  components: {},
  props: {
    date: String
    // item_day: Object
  },
  computed: {
    item_day() {
      let item = {};
      let items = this.calendar_store.hotel_comparative_dates;
      if (Object.keys(items).length > 0 && items.hasOwnProperty(this.date)) {
        item = items[this.date];
      }
      return item;
    },
    date_lyn() {
      return this.getFriendlyDate(
        Vue.moment(this.item_day.date)
          .subtract(1, "year")
          .format("YYYY-MM-DD")
      );
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    eventColor(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    },
    getKpisDay(date) {
      this.$store
        .dispatch("fetchRmsKpisDay", date)
        .then(response => {
          this.rows_kpis = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getKpisDayPickups(date) {
      this.$store
        .dispatch("fetchRmsKpisDayPickups", date)
        .then(response => {
          this.rows_pickups = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    async getHotelComparativeDates() {
      await this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: new Date(
            Vue.moment(this.date).format("YYYY"),
            0,
            1
          ).format("YYYY-MM-DD"),
          to_date: new Date(
            Vue.moment(this.date).format("YYYY"),
            11,
            31
          ).format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    mounted() {
      this.getKpisDay(this.date);
      this.getKpisDayPickups(this.date);
      this.getHotelComparativeDates();
    }
  }
};
</script>
<style lang="scss" scoped>
.events-day {
  .col {
    display: flex;
    gap: 15px;
    width: 100%;

    .v-card {
      flex: 1;

      .events-wrap {
        gap: 5px;
      }
    }
  }
}
</style>
