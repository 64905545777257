<template>
  <a-card :title="$t('how_guest_rate_you')">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row>
          <v-col cols="12" lg="4">
            <a-reputation-list
              :items="items"
              :kpi="'rating'"
            ></a-reputation-list>
          </v-col>
          <v-col cols="12" lg="8">
            <a-reputation-daily-rating-graph
              :height="$vuetify.breakpoint.lgAndUp ? '100%' : '200px'"
            ></a-reputation-daily-rating-graph>
          </v-col>
        </v-row>
      </template>

      <template v-slot:loading>
        <v-row>
          <v-col cols="12" lg="4">
            <v-skeleton-loader
              class="mb-4"
              type="list-item-avatar"
            ></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader
              class="mb-2"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" lg="8">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aReputationList from "@/components/reputation/aReputationList";
import aReputationDailyRatingGraph from "./aReputationDailyRatingGraph.vue";

export default {
  name: "a-reputation-rating",
  components: {
    aReputationList,
    aCard,
    aReputationDailyRatingGraph,
    aBaseComponent
  },
  props: {
    kpi: {
      type: String,
      default: null
    }
  },
  data: () => ({
    subscribed_modules: ["bi_report", "bi_daily_report"]
  }),
  methods: {},
  computed: {
    items() {
      var items = [];
      items.push({
        main: true,
        name: "veetal_rating",
        logo: "/img/logo/veetal-imagotipo-sma.png",
        value: parseFloat(this.bi_report.report.hawg_rating) || null,
        vs_value:
          parseFloat(this.bi_report.data_to_compare.hawg_rating) || null,
        increment: parseFloat(this.bi_report.increments.hawg_rating) || null
      });
      for (var ota of this.otas) {
        items.push({
          name: ota.code,
          logo: ota.logo,
          value:
            parseFloat(this.bi_report.report[ota.code + "_rating"]) || null,
          vs_value:
            parseFloat(this.bi_report.data_to_compare[ota.code + "_rating"]) ||
            null,
          increment:
            parseFloat(this.bi_report.increments[ota.code + "_rating"]) || null
        });
      }
      return items;
    },
    ...mapState({
      bi_report: state => state.bi.report,
      bi_daily_report: state => state.bi.daily_report,
      otas: state => state.otas
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
