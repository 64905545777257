<template>
  <v-tooltip color="white" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" class="mr-1" color="secondary" small>
        mdi-comment-outline
      </v-icon>
    </template>
    <div class="pt-2 pb-1 ">
      <h3
        class="black--text mb-2 text-center text-uppercase"
        v-html="label"
      ></h3>
      <div
        v-for="(comment, index) in filtered_comments"
        :key="index"
        class="mb-1 d-flex justify-center"
      >
        <v-chip small>
          {{ comment.comment }}
        </v-chip>
      </div>
    </div>
  </v-tooltip>
</template>
<script>
import { getFriendlyDayStrategy } from "@/utils.js";

export default {
  name: "a-rms-strategy-day-comments",
  props: {
    date: String,
    comments: Array
  },
  computed: {
    label() {
      return getFriendlyDayStrategy(this.date, true);
    },
    filtered_comments() {
      const max_comments = 3;
      const max_chars = 30;
      const truncatedComments = this.comments
        .slice(0, max_comments)
        .map(comment => {
          return {
            ...comment,
            comment:
              comment.comment.length > max_chars
                ? comment.comment.slice(0, max_chars) + " ..."
                : comment.comment
          };
        });
      if (this.comments.length > max_comments) {
        truncatedComments.push({ comment: "..." });
      }
      return truncatedComments;
    }
  }
};
</script>
