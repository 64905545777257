<template>
  <a-card :title="$t('overview')">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row>
          <v-col cols="6" lg="3" class="py-4 py-lg-0">
            <a-reputation-reviews-graph
              :icon="'mdi-comment-multiple-outline'"
              :increment="bi_report.increments[prefix + 'new_reviews']"
              :value="bi_report.report[prefix + 'new_reviews']"
              :vs_value="bi_report.data_to_compare[prefix + 'new_reviews']"
              :kpi="prefix + 'new_reviews'"
            ></a-reputation-reviews-graph>
            <a-label class="mt-2 text-center">
              {{ $t("new_reviews") }}
            </a-label>
          </v-col>
          <v-col cols="6" lg="3" class="py-4 py-lg-0">
            <v-list class="py-0">
              <header class="grey--text ml-5 mb-2 text-uppercase">
                {{ $t("reviews") }}
              </header>
              <v-list-item
                class="no-click"
                v-for="emotion of emotions"
                :key="emotion.code"
              >
                <v-list-item-avatar
                  size="30"
                  dark
                  :color="emotion.color"
                  class="white--text justify-center"
                >
                  {{ bi_report.report[prefix + "new_reviews_" + emotion.code] }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t(emotion.code + "s")
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <a-increment
                      v-if="
                        bi_report.increments[
                          prefix + 'new_reviews_' + emotion.code
                        ]
                      "
                      :value="
                        bi_report.data_to_compare[
                          prefix + 'new_reviews_' + emotion.code
                        ]
                      "
                      :kpi="prefix + 'new_reviews_' + emotion.code"
                      :increment="
                        bi_report.increments[
                          prefix + 'new_reviews_' + emotion.code
                        ]
                      "
                      :increment_type="'percent'"
                    ></a-increment>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" lg="3" class="d-flex justify-center py-4 py-lg-0">
            <a-ellipse-kpi
              ref="ellipse_kpi_nps"
              :kpi="prefix + 'nps'"
            ></a-ellipse-kpi>
          </v-col>
          <v-col cols="6" lg="3" class="d-flex justify-center py-4 py-lg-0">
            <div v-if="bi_report.report[prefix + 'answerable']">
              <a-ellipse
                :text="'/' + bi_report.report[prefix + 'answerable']"
                :done="parseFloat(bi_report.report[prefix + 'answered'])"
                :percent="parseFloat(bi_report.report[prefix + 'answered_avg'])"
                :increment="bi_report.increments[prefix + 'answered']"
                :vs_value="bi_report.data_to_compare[prefix + 'answered']"
                :kpi="'answered'"
                :icon="'mdi-comment-check-outline'"
              ></a-ellipse>
              <a-label class="mt-2 text-center">
                {{ $t("answered_reviews") }}
              </a-label>
            </div>
            <div v-else class="text-center">
              <v-icon class="text-h1 py-10" color="grey lighten-3"
                >mdi-check</v-icon
              >
              <p class="subtitle-2 px-6 mb-0 grey--text">
                {{ $t("no_answerable_reviews") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:loading>
        <v-row>
          <v-col cols="3" class="py-0">
            <v-skeleton-loader
              class="mx-auto"
              height="170"
              max-width="200"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto d-flex justify-center mt-2"
              max-width="200"
              type="heading"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-skeleton-loader
              class="mb-4"
              type="list-item-avatar"
            ></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-skeleton-loader
              class="mx-auto"
              height="170"
              max-width="200"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto d-flex justify-center mt-2"
              max-width="200"
              type="heading"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-skeleton-loader
              class="mx-auto"
              height="170"
              max-width="200"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto d-flex justify-center mt-2"
              max-width="200"
              type="heading"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aEllipse from "@/components/aEllipse";
import aEllipseKpi from "@/components/aEllipseKpi";
import aReputationReviewsGraph from "@/components/reputation/aReputationReviewsGraph";
import aIncrement from "@/components/aIncrement";
import aLabel from "@/components/aLabel";

export default {
  name: "a-reputation-resume",
  components: {
    aCard,
    aIncrement,
    aEllipse,
    aEllipseKpi,
    aReputationReviewsGraph,
    aBaseComponent,
    aLabel
  },
  props: {
    ota: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    subscribed_modules: ["bi_report"]
  }),
  methods: {},
  mounted() {},
  computed: {
    prefix() {
      return this.ota ? this.ota + "_" : "";
    },
    ...mapState({
      bi_report: state => state.bi.report,
      emotions: state => state.emotions
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
