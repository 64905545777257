<template>
  <div>
    <a-chm-row-days :days="days" :maxSlides="maxSlides"></a-chm-row-days>
    <v-virtual-scroll
      :items="rows"
      :item-height="44"
      :height="500"
      class="overflow-x-hidden"
    >
      <template v-slot:default="{ item, index }">
        <a-chm-row-availability
          :rowData="item"
          :rowIndex="index"
          v-if="item.isRoom"
        ></a-chm-row-availability>
        <a-chm-row-rate
          :rowData="item"
          :rowIndex="index"
          v-if="item.isRate"
          :showChannels="false"
        ></a-chm-row-rate>
        <a-chm-row-channel
          :rowData="item"
          :rowIndex="index"
          v-if="item.isChannel"
        ></a-chm-row-channel>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import aChmRowAvailability from "@/components/channel-manager/aChmRowAvailability.vue";
import aChmRowDays from "@/components/channel-manager/aChmRowDays.vue";
import aChmRowRate from "@/components/channel-manager/aChmRowRate.vue";
import aChmRowChannel from "@/components/channel-manager/aChmRowChannel.vue";
export default {
  components: {
    aChmRowAvailability,
    aChmRowDays,
    aChmRowRate,
    aChmRowChannel
  },
  props: {
    days: Array,
    rows: Array,
    maxSlides: Number
  }
};
</script>
<style lang="scss" scoped>
.overflow-x-hidden {
  overflow-x: hidden;
}
::v-deep .channel-row {
  background-color: #e5f2f6;
}
</style>
