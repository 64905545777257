<template>
  <v-row>
    <v-col cols="4" class="py-0">
      <!-- Select a hotel from siteminder_hotels -->
      <v-select
        v-model="item.settings.siteminder_hotel_id"
        :items="hotels_select"
        :label="$t('select_siteminder_hotel')"
        outlined
        rounded
        dense
        @change="changeSiteminderHotel"
      ></v-select>
    </v-col>
    <v-col cols="4" class="py-0">
      <v-text-field
        v-model="item.settings.siteminder_hotel_id"
        :label="$t('siteminder_hotel_id')"
        type="text"
        outlined
        rounded
        dense
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="py-0">
      <v-text-field
        v-model="item.settings.siteminder_version"
        :label="$t('siteminder_version')"
        type="number"
        outlined
        rounded
        dense
        disabled
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import * as Utils from "@/utils";
import { mapState } from "vuex";

export default {
  name: "a-hotel-configuration-siteminder",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    rules_number() {
      return [v => (v && v > 0) || this.$t("validation_number_must_positive")];
    },
    hotels_select() {
      return this.siteminder_hotels
        .map(hotel => {
          return {
            text: hotel.hotel_name,
            value: hotel.id
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    ...mapState({
      siteminder_hotels: state => state.hotel.siteminder_hotels
    })
  },
  methods: {
    getSiteminderHotels() {
      this.$store
        .dispatch("getSiteminderHotels")
        .then(() => {})
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    changeSiteminderHotel() {
      this.item.settings.siteminder_version = this.siteminder_hotels.find(
        hotel => hotel.id === this.item.settings.siteminder_hotel_id
      ).version;
    }
  },
  mounted() {
    this.getSiteminderHotels();
  }
};
</script>
