import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import * as layout from "@/store/modules/layout.js";
import * as login from "@/store/modules/login.js";
import * as snackbar from "@/store/modules/snackbar.js";
import * as user from "@/store/modules/user.js";
import * as language from "@/store/modules/language.js";
import * as hotel from "@/store/modules/hotel.js";
import * as currency from "@/store/modules/currency.js";
import * as chain from "@/store/modules/chain.js";
import * as rate from "@/store/modules/rate.js";
import * as rule from "@/store/modules/rule.js";
import * as bi from "@/store/modules/data/bi.js";
import * as highlight from "@/store/modules/data/highlight.js";
import * as parity from "@/store/modules/data/parity.js";
import * as rms from "@/store/modules/data/rms.js";
import * as trial from "@/store/modules/trial.js";
import * as connectapi from "@/store/modules/connectapi.js";
import * as calendar from "@/store/modules/calendar.js";
import * as budget from "@/store/modules/budget.js";
import * as channelmanager from "@/store/modules/channelmanager.js";
import * as roomtype from "@/store/modules/roomtype.js";
import * as metrics from "@/store/modules/metrics.js";
import * as dataimport from "@/store/modules/dataimport.js";
import * as segmentschannels from "@/store/modules/segmentschannels.js";
import * as specialrates from "@/store/modules/specialrates.js";
import * as offers from "@/store/modules/offers.js";
import * as Utils from "@/utils.js";
import i18n from "@/plugins/i18n";

Vue.use(Vuex);
const debug = false && process.env.NODE_ENV === "development";
export default new Vuex.Store({
  plugins: debug ? [createLogger()] : [],
  modules: {
    language,
    layout,
    login,
    snackbar,
    user,
    hotel,
    currency,
    chain,
    rate,
    rule,
    bi,
    highlight,
    parity,
    rms,
    trial,
    connectapi,
    calendar,
    channelmanager,
    specialrates,
    offers,
    roomtype,
    budget,
    metrics,
    dataimport,
    segmentschannels
  },
  state: {
    app_width: 0,
    share_content: false,
    share_image_src: null,
    share_image_width: 0,
    share_image_height: 0,
    loading_screenshot: false,
    datatable: "no-data-text",
    mini: true,
    drawer: null,
    menu_extended: false,
    period_filter_selected_option: "month",
    period_filter_selected_year: "",
    highlight_priorities: [
      {
        id: 3,
        literal: "high",
        color: "indigo darken-2",
        hexacolor: "#303F9F",
        icon: "mdi-alert-octagon-outline"
      },
      {
        id: 2,
        literal: "medium",
        color: "blue accent-3",
        hexacolor: "#2979FF",
        icon: "mdi-alert-outline"
      },
      {
        id: 1,
        literal: "low",
        color: "cyan darken-1",
        hexacolor: "#00ACC1",
        icon: "mdi-information-outline"
      }
    ],
    parity_colors: {
      ok: "green",
      impaired: "teal",
      low: "orange",
      critical: "red"
    },
    parity_styles: {
      ok: "background-color: #1B998B;height: 80%;",
      impaired: "background-color: #2e21bd;height: 100%;",
      low: "background-color: #FAA916;height: 50%;",
      critical: "background-color: #e64d3c;height: 25%;"
    },
    emotions: [
      {
        code: "negative",
        color: "red",
        color_hexa: "#e64d3c"
      },
      {
        code: "neutral",
        color: "orange",
        color_hexa: "#FAA916"
      },
      {
        code: "positive",
        color: "green",
        color_hexa: "#1B998B"
      }
    ],
    times_to_answer: [
      {
        id: 1,
        code: "answered_today",
        literal: "first_day",
        color: "green",
        color_hexa: "#1B998B"
      },
      {
        id: 2,
        code: "answered_tomorrow",
        literal: "second_day",
        color: "orange",
        color_hexa: "#FAA916"
      },
      {
        id: 3,
        code: "answered_later",
        literal: "more_two_days",
        color: "red",
        color_hexa: "#e64d3c"
      }
    ],
    parity_steps: [
      {
        id: 1,
        code: "agressive",
        literal: "agressive",
        color: "primary",
        color_hexa: "#2e21bd"
      },
      {
        id: 2,
        code: "conservative",
        literal: "conservative",
        color: "green",
        color_hexa: "#1B998B"
      },
      {
        id: 3,
        code: "misaligned",
        literal: "misaligned",
        color: "orange",
        color_hexa: "#FAA916"
      },
      {
        id: 4,
        code: "expensive",
        literal: "too_expensive",
        color: "red",
        color_hexa: "#e64d3c"
      }
    ],
    chart_settings: {
      crosshair_settings: {
        plotLabel: {
          jsRule: "window.customFncs.getTooltip()",
          shadow: true,
          shadowAlpha: 0.3,
          shadowDistance: "4px",
          shadowColor: "#666666",
          borderColor: "#999999",
          borderWidth: 1,
          backgroundColor: "#ffffff",
          fontFamily: "Roboto Condensed",
          fontWeight: "400",
          fontColor: "#333333",
          fontSize: "14px",
          lineHeight: "18px",
          padding: "5px 10px 10px 10px",
          borderRadius: "3px",
          textAlign: "center",
          htmlMode: true,
          thousandsSeparator: ".",
          decimalsSeparator: ","
        },
        visible: true,
        trigger: "hover",
        backgroundColor: "white",
        lineStyle: "dashed",
        lineColor: "#2071e3",
        lineWidth: 2,
        marker: {
          backgroundColor: "#2071e3",
          size: 5,
          borderWidth: 1,
          opacity: "0.8",
          shadow: 2
        },
        "scale-label": {
          "font-color": "#ffffff",
          "font-size": 12,
          "background-color": "#2071e3",
          "border-width": 0,
          "border-radius": "3px"
        }
      },
      tooltip: {
        shadow: true,
        shadowAlpha: 0.3,
        shadowDistance: "4px",
        shadowColor: "#666666",
        borderColor: "#999999",
        borderWidth: 1,
        backgroundColor: "#ffffff",
        fontFamily: "Roboto Condensed",
        fontWeight: "400",
        fontColor: "#333333",
        fontSize: "14px",
        lineHeight: "18px",
        padding: "5px 10px 10px 10px",
        borderRadius: "3px",
        textAlign: "center",
        htmlMode: true,
        thousandsSeparator: ".",
        decimalsSeparator: ","
      },
      globals: {
        fontFamily: "Roboto Condensed",
        fontWeight: "400",
        borderRadiusBars: "10px",
        scaleColor: "#cacaca"
      },
      gui: {
        contextMenu: {
          empty: true
        }
      }
    },
    otas: [
      {
        code: "booking",
        color: "#003382",
        logo: "/img/otas/booking.jpg"
      },
      {
        code: "googlebusiness",
        color: "#5e04db",
        logo: "/img/otas/google.webp"
      },
      {
        code: "tripadvisor",
        color: "#34e0a1",
        logo: "/img/otas/tripadvisor.png"
      },
      {
        code: "expedia",
        color: "#fdc100",
        logo: "/img/otas/expedia.jpg"
      }
    ],
    // Period Filter
    selected_period: "",
    selected_year: 0,
    selected_month: 0,
    vs_filter_selected_option: "ly",
    vs_hotel_selected: null,
    otb_option: "otb",
    //Options
    options: ["year", "month"],
    show_for_pdf: false,
    // vs_options: ["ly", "cs", "sply", "bu", "other_hotel"],
    vs_options: ["ly"],
    available_months: {},
    // Extraction Filter
    extraction_id: 0,
    vs_id: 0,
    pax: 0,
    childs: 0,
    regime: [],
    cancel_policy: [],
    genius: false,
    mobile_device: false,
    rate_date_to_compare: "",
    // Highlights
    highlights_extraction_id: 0,
    // Hotelsdot
    hotelsdot: {
      series_ui: {
        otb: {
          name: "otb",
          color: "#0077b6",
          type: "vs"
        },
        ly: {
          name: "ly",
          color: "#f4a261",
          type: "vs"
        },
        lyn: {
          name: "lyn",
          color: "#e76f51",
          type: "vs"
        },
        stly: {
          name: "stly",
          color: "#e76f51",
          type: "vs"
        },
        stlyn: {
          name: "stlyn",
          color: "#035f91",
          type: "vs"
        },
        bud: {
          name: "bud",
          color: "#2a9d8f",
          type: "vs"
        },
        bud_ly: {
          name: "bud_ly",
          color: "#035f91",
          type: "vs"
        },
        ytd: {
          name: "ytd",
          color: "#035f91",
          type: "vs"
        },
        mtd: {
          name: "mtd",
          color: "#035f91",
          type: "vs"
        },
        full_year: {
          name: "full_year",
          color: "#035f91",
          type: "vs"
        },
        full_month: {
          name: "full_month",
          color: "#035f91",
          type: "vs"
        },
        pickup_1: {
          name: "pickup_1",
          color: "#035f91",
          type: "vs"
        },
        pickup_2: {
          name: "pickup_2",
          color: "#035f91",
          type: "vs"
        },
        pickup_3: {
          name: "pickup_3",
          color: "#035f91",
          type: "vs"
        },
        pickup_4: {
          name: "pickup_4",
          color: "#035f91",
          type: "vs"
        },
        pickup_5: {
          name: "pickup_5",
          color: "#035f91",
          type: "vs"
        },
        pickup_6: {
          name: "pickup_6",
          color: "#035f91",
          type: "vs"
        },
        pickup_stly: {
          name: "Pickup_stly",
          color: "#035f91",
          type: "vs"
        },
        pickup_stly_6: {
          name: "Pickup_stly_6",
          color: "#e76f51",
          type: "vs"
        },
        pickup_stly_5: {
          name: "Pickup_stly_5",
          color: "#e76f51",
          type: "vs"
        },
        pickup_stly_4: {
          name: "Pickup_stly_4",
          color: "#e76f51",
          type: "vs"
        },
        pickup_stly_3: {
          name: "Pickup_stly_3",
          color: "#e76f51",
          type: "vs"
        },
        pickup_stly_2: {
          name: "Pickup_stly_2",
          color: "#e76f51",
          type: "vs"
        },
        pickup_stly_1: {
          name: "Pickup_stly_1",
          color: "#e76f51",
          type: "vs"
        },
        //Segments
        otas: {
          name: "otas",
          color: "#dc6a3d",
          type: "segment"
        },
        aavv: {
          name: "aavv",
          color: "#4f5d2f",
          type: "segment"
        },
        be: {
          name: "be",
          color: "#564d80",
          type: "segment"
        },
        corp: {
          name: "corp",
          color: "#f4c95d",
          type: "segment"
        },
        crs: {
          name: "crs",
          color: "#839788",
          type: "segment"
        },
        gpos: {
          name: "gpos",
          color: "#f4a261",
          type: "segment"
        },
        promo: {
          name: "promo",
          color: "#4f5d2f",
          type: "segment"
        },
        // Channels
        dir: {
          name: "dir",
          color: "#0077b6",
          type: "channel"
        },
        ag1: {
          name: "dir",
          color: "#f4a261",
          type: "channel"
        },
        ag2: {
          name: "dir",
          color: "#e76f51",
          type: "channel"
        },
        ag3: {
          name: "dir",
          color: "#dc6a3d",
          type: "channel"
        },
        agg: {
          name: "dir",
          color: "#4f5d2f",
          type: "channel"
        },
        agv: {
          name: "dir",
          color: "#564d80",
          type: "channel"
        },
        bdc: {
          name: "dir",
          color: "#56a3a6",
          type: "channel"
        },
        cas: {
          name: "dir",
          color: "#f4c95d",
          type: "channel"
        },
        cen: {
          name: "dir",
          color: "#086788",
          type: "channel"
        },
        cnt: {
          name: "dir",
          color: "#4f5d2f",
          type: "channel"
        },
        emv: {
          name: "dir",
          color: "#f4a261",
          type: "channel"
        },
        exp: {
          name: "dir",
          color: "#564d80",
          type: "channel"
        },
        gpd: {
          name: "dir",
          color: "#c7efcf",
          type: "channel"
        },
        gpi: {
          name: "dir",
          color: "#FE5F55",
          type: "channel"
        },
        hbd: {
          name: "dir",
          color: "#086788",
          type: "channel"
        },
        sel: {
          name: "dir",
          color: "#f0b67f",
          type: "segment"
        },
        vii: {
          name: "dir",
          color: "#d6d1b1",
          type: "channel"
        },
        wkd: {
          name: "dir",
          color: "#eef5db",
          type: "channel"
        },

        dbl: {
          name: "dbl",
          color: "#084c61",
          type: "roomtype"
        },
        deluxe: {
          name: "dir",
          color: "#961D4E",
          type: "roomtype"
        },
        suite: {
          name: "suite",
          color: "#9CAFB7",
          type: "roomtype"
        },
        sup: {
          name: "sup",
          color: "#00916E",
          type: "roomtype"
        }
      }
    },
    reload_subscribed_modules: 0
  },
  mutations: {
    SET_APP_WIDTH(state, payload) {
      state.app_width = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_MINI(state, payload) {
      state.mini = payload;
    },
    SET_MENU_EXTENDED(state, payload) {
      state.menu_extended = payload;
    },
    SET_PERIOD_FILTER_SELECTED_OPTION(state, payload) {
      state.period_filter_selected_option = payload;
    },
    SET_PERIOD_FILTER_SELECTED_YEAR(state, payload) {
      state.period_filter_selected_year = payload;
    },
    SET_VS_FILTER_SELECTED_OPTION(state, payload) {
      state.vs_filter_selected_option = payload;
    },
    SET_SELECTED_PERIOD(state, payload) {
      state.selected_period = payload;
    },
    SET_SELECTED_YEAR(state, payload) {
      state.selected_year = payload;
    },
    SET_SELECTED_MONTH(state, payload) {
      state.selected_month = payload;
    },
    SET_EXTRACTION_ID(state, payload) {
      state.extraction_id = payload;
    },
    SET_RATE_FILTERS(state, payload) {
      state.extraction_id = payload.extraction_id;
      state.vs_id = payload.vs_id;
      state.pax = payload.pax;
      state.childs = payload.childs;
      state.regime = payload.regime;
      state.cancel_policy = payload.cancel_policy;
      state.genius = payload.genius;
      state.mobile_device = payload.mobile_device;
    },
    SET_RATE_DATE_TO_COMPARE(state, payload) {
      state.rate_date_to_compare = payload;
    },
    SET_HIGHLIGHTS_EXTRACTION_ID(state, payload) {
      state.highlights_extraction_id = payload;
    },
    SET_VS_HOTEL_SELECTED(state, payload) {
      state.vs_hotel_selected = payload;
    },
    SET_OTB_OPTION(state, payload) {
      state.otb_option = payload;
    },
    SET_SHARE_CONTENT(state, payload) {
      state.share_content = payload;
    },
    SET_SHARE_IMAGE_SRC(state, payload) {
      state.share_image_src = payload;
    },
    SET_SHARE_IMAGE_WIDTH(state, payload) {
      state.share_image_width = payload;
    },
    SET_SHARE_IMAGE_HEIGHT(state, payload) {
      state.share_image_height = payload;
    },
    SET_LOADING_SCREENSHOT(state, payload) {
      state.loading_screenshot = payload;
    },
    SET_RELOAD_SUBSCRIBED_MODULES(state) {
      state.reload_subscribed_modules++;
    },
    SET_SHOW_FOR_PDF(state, payload) {
      state.show_for_pdf = payload;
    }
  },
  actions: {
    setAppWidth({ commit }, v) {
      commit("SET_APP_WIDTH", v);
    },
    setDrawer({ commit }, v) {
      commit("SET_DRAWER", v);
    },
    setMini({ commit }, v) {
      commit("SET_MINI", v);
    },
    setMenuExtended({ commit }, v) {
      commit("SET_MENU_EXTENDED", v);
    },
    setPeriodFilterSelectedOption({ commit }, v) {
      commit("SET_PERIOD_FILTER_SELECTED_OPTION", v);
    },
    setVsFilterSelectedOption({ commit }, v) {
      commit("SET_VS_FILTER_SELECTED_OPTION", v);
    },
    setPeriodFilterSelectedYear({ commit }, v) {
      commit("SET_PERIOD_FILTER_SELECTED_YEAR", v);
    },
    setSelectedPeriod({ commit }, v) {
      commit("SET_SELECTED_PERIOD", v);
    },
    setSelectedYear({ commit }, v) {
      commit("SET_SELECTED_YEAR", v);
    },
    setSelectedMonth({ commit }, v) {
      commit("SET_SELECTED_MONTH", v);
    },
    setExtractionId({ commit }, v) {
      commit("SET_EXTRACTION_ID", v);
    },
    setRateFilters({ commit }, v) {
      commit("SET_RATE_FILTERS", v);
    },
    setHighlightsExtractionId({ commit }, v) {
      commit("SET_HIGHLIGHTS_EXTRACTION_ID", v);
    },
    setVsHotelSelected({ commit }, v) {
      commit("SET_VS_HOTEL_SELECTED", v);
    },
    setOtbOption({ commit }, v) {
      commit("SET_OTB_OPTION", v);
    },
    setShareContent({ commit }, v) {
      commit("SET_SHARE_CONTENT", v);
    },
    setShareImageSrc({ commit }, v) {
      commit("SET_SHARE_IMAGE_SRC", v);
    },
    setShareImageWidth({ commit }, v) {
      commit("SET_SHARE_IMAGE_WIDTH", v);
    },
    setShareImageHeight({ commit }, v) {
      commit("SET_SHARE_IMAGE_HEIGHT", v);
    },
    setLoadingScreenshot({ commit }, v) {
      commit("SET_LOADING_SCREENSHOT", v);
    },
    setReloadSubscribedModules({ commit }) {
      commit("SET_RELOAD_SUBSCRIBED_MODULES");
    },
    setShowForPdf({ commit }, v) {
      commit("SET_SHOW_FOR_PDF", v);
    }
  },
  getters: {
    available_years: state => {
      return Object.keys(state.available_months);
    },
    current_user: (state, getters) => {
      return state.login.current_user;
    },
    current_hotel_language2: (state, getters) => {
      return getters.current_hotel &&
        state.language &&
        getters.current_hotel.settings.locale
        ? state.language.language_options.find(
            language =>
              language.locale === getters.current_hotel.settings.locale
          )
        : state.language.language_options[0];
    },

    current_hotel_language: (state, getters) => {
      const currentHotel = getters.current_hotel;
      const languageOptions = state.language
        ? state.language.language_options
        : [];

      if (currentHotel && state.language) {
        // const { locale, locale_decimals } = currentHotel.settings;
        const locale = currentHotel.settings.locale || "es-ES";
        const locale_decimals = currentHotel.settings.locale_decimals || 1;
        return (
          languageOptions.find(
            language =>
              language.locale === locale &&
              language.decimals === locale_decimals
          ) || languageOptions.find(language => language.locale === locale)
        );
      }

      return languageOptions[0];
    },
    current_hotel: (state, getters) => {
      return state.route && state.route.params.hotel_slug
        ? getters.getHotelBySlug(state.route.params.hotel_slug)
        : state.hotel.hotels[0];
    },
    current_section: state => {
      return state.route && state.route.meta.section
        ? state.route.meta.section
        : "revenue";
    },
    has_permission: (state, getters) => {
      return permission => {
        return (
          getters.current_hotel &&
          getters.current_user &&
          getters.current_hotel.permissions[permission] &&
          getters.current_user.permissions[permission]
        );
      };
    },
    highlights_by_priority: (state, getters) => {
      var highlights_by_priority = [];
      for (var priority of state.highlight_priorities) {
        var total = state.highlight.list.filter(h => h.priority == priority.id)
          .length;
        var done = state.highlight.list.filter(
          h =>
            h.priority == priority.id &&
            getters.current_user.settings.highlights_done &&
            getters.current_user.settings.highlights_done.indexOf(h.id) >= 0
        ).length;
        var percent = Utils.getPercent(done, total);
        highlights_by_priority.push({
          done: done,
          percent: percent,
          priority_id: priority.id,
          total: total
        });
      }

      return highlights_by_priority;
    },
    highlights_by_type: (state, getters) => {
      var highlights_by_type = [];
      if (Array.isArray(state.highlight.info.highlight_types)) {
        for (var type of state.highlight.info.highlight_types) {
          var highlights_by_priority = [];
          for (var priority of state.highlight_priorities) {
            var total = state.highlight.list.filter(
              h => h.highlight_type_id == type.id && h.priority == priority.id
            ).length;
            var done = state.highlight.list.filter(
              h =>
                h.highlight_type_id == type.id &&
                h.priority == priority.id &&
                getters.current_user.settings.highlights_done &&
                getters.current_user.settings.highlights_done.indexOf(h.id) >= 0
            ).length;
            var percent = Utils.getPercent(done, total);
            highlights_by_priority.push({
              done: done,
              percent: percent,
              priority_id: priority.id,
              total: total
            });
          }

          highlights_by_type.push({
            highlight_type_id: type.id,
            priorities: highlights_by_priority,
            total: state.highlight.list.filter(
              h => h.highlight_type_id == type.id
            )
          });
        }
        highlights_by_type.sort((a, b) => {
          return a.total < b.total ? 1 : b.total < a.total ? -1 : 0;
        });
      }

      return highlights_by_type;
    },
    is_current_period: (state, getters) => {
      return (
        (state.selected_period === "month" &&
          state.selected_year ===
            Vue.moment()
              .subtract(1, "days")
              .format("YYYY") &&
          state.selected_month ===
            parseInt(
              Vue.moment()
                .subtract(1, "days")
                .format("M")
            )) ||
        (state.selected_period === "year" &&
          state.selected_year ===
            Vue.moment()
              .subtract(1, "days")
              .format("YYYY") &&
          state.selected_month === null)
      );
    },
    current_period_items: (state, getters) => {
      var from = "";
      var to = "";
      var format = "D MMM YYYY";
      var items = [];
      switch (state.selected_period) {
        case "month":
          for (var item of state.bi.daily_report.daily_list) {
            items.push(
              Vue.moment(item.date_original.toString()).format("D MMM YYYY")
            );
          }

          break;
        case "year":
          // From first of year to end of year
          from = Vue.moment(state.selected_year + "-01-01");
          // To:
          //  - ytd : last closed month
          //  - otb : end of year
          to = Vue.moment(state.selected_year + "-12-31").endOf("year");
          if (state.otb_option == "ytd" && getters.isCurrent) {
            to = Vue.moment()
              .subtract(1, "month")
              .endOf("month");
          }

          for (var month = 0; month < 12; month++) {
            items.push(
              Utils.capitalizeFirstLetter(
                from
                  .clone()
                  .add(month, "month")
                  .format("MMMM YYYY")
              )
            );
          }

          break;
      }
      return items;
    },
    vs_period_items: (state, getters) => {
      var from = "";
      var to = "";
      var format = "D MMM YYYY";
      var items = [];
      if (state.vs_filter_selected_option == "ly") {
        switch (state.selected_period) {
          case "month":
            for (var item of state.bi.daily_report.daily_list_to_compare) {
              items.push(
                Vue.moment(item.date_original.toString()).format("D MMM YYYY")
              );
            }
            break;
          case "year":
            // From first of year to end of year
            from = Vue.moment(state.selected_year + "-01-01");
            // To:
            //  - ytd : last closed month
            //  - otb : end of year
            to = Vue.moment(state.selected_year + "-12-31").endOf("year");
            if (state.otb_option == "ytd" && getters.isCurrent) {
              to = Vue.moment()
                .subtract(1, "month")
                .endOf("month");
            }

            for (var month = 0; month < 12; month++) {
              items.push(
                Utils.capitalizeFirstLetter(
                  from
                    .clone()
                    .add(month, "month")
                    .format("MMMM YYYY")
                )
              );
            }

            break;
        }
      }
      if (state.vs_filter_selected_option == "bu") {
        for (var item1 of getters.current_period_items) {
          items.push(i18n.t("bu"));
        }
      }
      if (state.vs_filter_selected_option == "cs") {
        for (var item2 of getters.current_period_items) {
          items.push(i18n.t("cs"));
        }
      }
      if (state.vs_filter_selected_option == "other_hotel") {
        for (var item3 of getters.current_period_items) {
          items.push(getters.getHotelById(state.vs_hotel_selected).name);
        }
      }
      return items;
    },
    current_period_index: (state, getters) => {
      var index = 0;
      var yesterday = Vue.moment().subtract(1, "days");
      switch (state.selected_period) {
        case "month":
          index = parseInt(yesterday.format("D")) - 1;

          break;
        case "year":
          index = parseInt(yesterday.format("M")) - 1;

          break;
      }
      return index;
    },
    current_period_label: state => {
      var index = 0;
      var yesterday = Vue.moment().subtract(1, "days");
      switch (state.selected_period) {
        case "month":
          index = parseInt(yesterday.format("D")) - 1;

          break;
        case "year":
          index = parseInt(yesterday.format("M")) - 1;

          break;
      }
      return index;
    },
    get_kpi: (state, getters) => kpi => {
      if (state.chain.chain.kpis && state.chain.chain.kpis[kpi]) {
        return state.chain.chain.kpis[kpi];
      } else if (kpi.includes("_")) {
        return getters.get_kpi(kpi.substr(kpi.indexOf("_") + 1));
      }
      return {};
    },
    get_ota: state => code => {
      return state.otas.find(o => o.code == code);
    },
    versions: state => {
      return [
        {
          version: "6.3",
          type: "major",
          date: "2020-12-15",
          title: "version_63_title",
          description: "version_63_description",
          items: [
            "version_63_bullet_1",
            "version_63_bullet_2",
            "version_63_bullet_3",
            "version_63_bullet_4"
          ],
          has_image: true
        },
        {
          version: "6.2",
          type: "major",
          date: "2021-09-15",
          title: "version_62_title",
          description: "version_62_description",
          items: [
            "version_62_bullet_1",
            "version_62_bullet_2",
            "version_62_bullet_3",
            "version_62_bullet_4"
          ],
          has_image: false
        },
        {
          version: "6.1",
          type: "major",
          date: "2020-07-10",
          title: "version_61_title",
          description: "version_61_description",
          items: [
            "version_61_bullet_1",
            "version_61_bullet_2",
            "version_61_bullet_3"
          ],
          has_image: false
        },
        {
          version: "6.0",
          type: "major",
          date: "2020-05-31",
          title: "version_60_title",
          description: "version_60_description",
          items: [
            "version_60_bullet_1",
            "version_60_bullet_2",
            "version_60_bullet_3",
            "version_60_bullet_4"
          ],
          has_image: false
        }
      ];
    }
  }
});
