<template>
  <div>
    <a-card class="mt-5 mb-2" :title="$t('Accumulative')">
      <div id="rms-year-sales-charts">
        <v-list nav class="mb-0 pb-0">
          <v-list-item-group
            v-model="selected_kpi"
            color="secondary"
            class="d-flex"
          >
            <v-list-item
              v-for="(item, i) in kpis"
              :key="i"
              class="mx-2 text-center mb-0"
              :class="{ show_for_pdf: show_for_pdf }"
            >
              <v-list-item-content class="ml-0 pr-3">
                <a-label class="mb-0">
                  {{ $t(item.label) }}
                </a-label>
                <div class="mb-3">
                  <a-period-compare
                    :periods="[year_period, 'stly']"
                  ></a-period-compare>
                </div>
                <a-kpi
                  v-if="rms.dashboard_annual_kpis"
                  :kpi="item.kpi"
                  :value="rms.dashboard_annual_kpis['otb'][item.kpi]"
                  :vs_values="[rms.dashboard_annual_kpis.stly[item.kpi]]"
                  :vs_periods="['stly']"
                  large
                ></a-kpi>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-row v-if="kpi_graph === 'room_revenue' || show_for_pdf">
          <v-col cols="12">
            <a-label v-if="show_for_pdf" class="my-4">
              {{ $t("room_revenue") }}
            </a-label>
            <a-hotelsdot-year-sales-roomrevenue-monthly-chart
              :series="year_sales_monthly_graph.kpis.room_revenue"
              :labels="year_sales_monthly_graph.labels"
              height="450"
            ></a-hotelsdot-year-sales-roomrevenue-monthly-chart>
          </v-col>
        </v-row>
      </div>

      <div id="rms-year-sales-adr-rn">
        <v-row v-if="kpi_graph === 'adr' || show_for_pdf">
          <v-col cols="12">
            <a-label v-if="show_for_pdf" class="my-4">
              {{ $t("adr") }}
            </a-label>
            <a-hotelsdot-year-sales-adr-monthly-chart
              :series="year_sales_monthly_graph.kpis.adr"
              :labels="year_sales_monthly_graph.labels"
              height="450"
            ></a-hotelsdot-year-sales-adr-monthly-chart>
          </v-col>
        </v-row>

        <v-row v-if="kpi_graph === 'rn' || show_for_pdf">
          <v-col cols="12">
            <a-label v-if="show_for_pdf" class="my-4">
              {{ $t("rn") }}
            </a-label>
            <a-hotelsdot-year-sales-rn-monthly-chart
              :series="year_sales_monthly_graph.kpis.rn"
              :labels="year_sales_monthly_graph.labels"
              height="450"
            ></a-hotelsdot-year-sales-rn-monthly-chart>
          </v-col>
        </v-row>
      </div>

      <div id="rms-year-sales-occupancy-revpar">
        <v-row v-if="kpi_graph === 'occupancy' || show_for_pdf">
          <v-col cols="12">
            <a-label v-if="show_for_pdf" class="my-4">
              {{ $t("occupancy") }}
            </a-label>
            <a-hotelsdot-year-sales-occupancy-monthly-chart
              :series="year_sales_monthly_graph.kpis.occupancy"
              :labels="year_sales_monthly_graph.labels"
              height="450"
            ></a-hotelsdot-year-sales-occupancy-monthly-chart>
          </v-col>
        </v-row>

        <v-row v-if="kpi_graph === 'revpar' || show_for_pdf">
          <v-col cols="12">
            <a-label v-if="show_for_pdf" class="my-4">
              {{ $t("revpar") }}
            </a-label>
            <a-hotelsdot-year-sales-revpar-monthly-chart
              :series="year_sales_monthly_graph.kpis.revpar"
              :labels="year_sales_monthly_graph.labels"
              height="450"
            ></a-hotelsdot-year-sales-revpar-monthly-chart>
          </v-col>
        </v-row>
      </div>

      <v-row
        v-if="kpi_graph === 'intermediation' || show_for_pdf"
        id="rms-year-sales-intermediation"
      >
        <v-col cols="12">
          <a-label v-if="show_for_pdf" class="my-4">
            {{ $t("intermediation") }}
          </a-label>
          <a-hotelsdot-year-sales-intermediation-monthly-chart
            :series="year_sales_monthly_graph.kpis.intermediation"
            :labels="year_sales_monthly_graph.labels"
            height="450"
          ></a-hotelsdot-year-sales-intermediation-monthly-chart>
        </v-col>
      </v-row>
    </a-card>

    <a-card
      id="rms-year-sales-charts-table"
      :title="$t('full_year')"
      class="mb-2"
    >
      <v-row>
        <v-col cols="12">
          <a-rms-year-sales-table
            :class="{ show_for_pdf: show_for_pdf }"
            :rows="year_sales_table"
          ></a-rms-year-sales-table>
        </v-col>
      </v-row>
    </a-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aCard from "@/components/aCard.vue";
import aRmsYearSalesTable from "@/components/rms/hotelsdot/aRmsYearSalesTable.vue";
import aHotelsdotYearSalesRoomrevenueMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRoomrevenueMonthlyChart.vue";
import aHotelsdotYearSalesAdrMonthlyChart from "@/components/chart/hotelsdot/aYearSalesAdrMonthlyChart.vue";
import aHotelsdotYearSalesRnMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRnMonthlyChart.vue";
import aHotelsdotYearSalesOccupancyMonthlyChart from "@/components/chart/hotelsdot/aYearSalesOccupancyMonthlyChart.vue";
import aHotelsdotYearSalesRevparMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRevparMonthlyChart.vue";
import aHotelsdotYearSalesIntermediationMonthlyChart from "@/components/chart/hotelsdot/aYearSalesIntermediationMonthlyChart.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aLabel from "@/components/aLabel.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "rms-year-sales-charts",
  components: {
    aKpi,
    aLabel,
    aPeriodCompare,
    aCard,
    aRmsYearSalesTable,
    aHotelsdotYearSalesRoomrevenueMonthlyChart,
    aHotelsdotYearSalesAdrMonthlyChart,
    aHotelsdotYearSalesRnMonthlyChart,
    aHotelsdotYearSalesOccupancyMonthlyChart,
    aHotelsdotYearSalesRevparMonthlyChart,
    aHotelsdotYearSalesIntermediationMonthlyChart
  },
  data: () => ({
    year_period: "ytd",
    selected_kpi: 0,
    kpis: [
      { id: "room_revenue", kpi: "room_revenue", label: "room_revenue" },
      { id: "adr", kpi: "adr", label: "adr" },
      { id: "rn", kpi: "rn", label: "rn" },
      { id: "occupancy", kpi: "occupancy", label: "occupancy" },
      { id: "revpar", kpi: "revpar", label: "revpar" },
      { id: "intermediation", kpi: "intermediation", label: "intermediation" }
    ]
  }),
  computed: {
    kpi_graph: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    ...mapState({
      rms: state => state.rms,
      show_for_pdf: state => state.show_for_pdf,
      year_sales_monthly_graph: state => state.rms.year_sales_monthly_graph,
      year_sales_table: state => state.rms.year_sales_table
    })
  },
  watch: {
    selected_kpi() {
      this.kpi_graph = this.kpis[this.selected_kpi].id;
    },
    kpi_graph() {
      this.selected_kpi = this.kpis.findIndex(kpi => kpi.id === this.kpi_graph);
    }
  },
  created() {
    this.kpi_graph = "room_revenue";
  }
};
</script>

<style scoped>
.show_for_pdf.v-list-item--link:before {
  opacity: 0;
}
.show_for_pdf ::v-deep .kpi-selected {
  background-color: transparent !important;
}
</style>
