<template>
  <a-card :title="$t('answer_behavior')">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row>
          <v-col
            :cols="
              all_reviews_answered ? 12 : $vuetify.breakpoint.lgAndUp ? 5 : 12
            "
            class="my-2"
            v-if="any_reviews_answered"
          >
            <v-row>
              <v-col cols="6" class="">
                <a-reputation-answer-time-graph></a-reputation-answer-time-graph>
                <a-label class="mt-2 text-center">
                  {{ $t("time_to_answer") }}
                </a-label>
              </v-col>
              <v-col cols="6" class="">
                <v-list class="py-0">
                  <header class="grey--text ml-5 mb-2 text-uppercase">
                    {{ $t("answered_review_in") }}
                  </header>
                  <v-list-item
                    class="no-click"
                    v-for="time of times_to_answer"
                    :key="time.code"
                  >
                    <v-list-item-avatar
                      size="30"
                      dark
                      :color="time.color"
                      class="white--text justify-center"
                    >
                      {{ bi_reputation_answered_by_time.data[time.code] }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t(time.literal)
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        <a-increment
                          :value="
                            bi_reputation_answered_by_time.data_to_compare[
                              time.code
                            ]
                          "
                          :kpi="time.code"
                          :increment="
                            bi_reputation_answered_by_time.increments[time.code]
                          "
                          :increment_type="'percent'"
                        ></a-increment>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12">
                <v-alert
                  :color="getQuickAnswerColor"
                  text
                  class="white--text mx-2 px-6 text-left"
                >
                  <v-row align="center">
                    <v-col class="shrink py-0">
                      <span class="text-h4">
                        {{
                          bi_reputation_answered_by_time.data.quick_answers_avg
                        }}%
                      </span>
                    </v-col>
                    <v-col class="grow py-0 pl-0">
                      {{ $t("quick_answers") }}
                    </v-col>
                    <v-col class="shrink py-0 d-visible">
                      <a-increment
                        class="text-h6"
                        :value="
                          bi_reputation_answered_by_time.data_to_compare
                            .quick_answers_avg
                        "
                        :kpi="'quick_answers_avg'"
                        :increment="
                          bi_reputation_answered_by_time.increments
                            .quick_answers_avg
                        "
                        :increment_type="'percent'"
                      ></a-increment>
                    </v-col>
                    <v-col class="shrink py-0">
                      <v-icon :color="getQuickAnswerColor" class="text-h4"
                        >mdi-flash-outline</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="!all_reviews_answered"
            :cols="
              !any_reviews_answered ? 12 : $vuetify.breakpoint.lgAndUp ? 7 : 12
            "
            class="py-0"
          >
            <v-sheet color="grey lighten-4" tile class="rounded">
              <v-card-subtitle
                class="text-center grey--text text-uppercase pb-0"
              >
                <v-avatar color="secondary" size="23" class="white--text">{{
                  bi_report.report.answerable - bi_report.report.answered
                }}</v-avatar>
                {{ $t("reviews_to_answer") }}
                <v-btn
                  class="mt-n1 ml-2"
                  icon
                  outlined
                  @click="cycle_reviews = !cycle_reviews"
                  color="secondary"
                  x-small
                >
                  <v-icon x-small v-if="cycle_reviews">
                    mdi-pause
                  </v-icon>
                  <v-icon x-small v-else>
                    mdi-play
                  </v-icon>
                </v-btn>
              </v-card-subtitle>
              <v-carousel
                light
                :cycle="cycle_reviews"
                hide-delimiter-background
                height="auto"
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="review in not_answered_reviews"
                  :key="review.id"
                >
                  <v-sheet
                    color="grey lighten-4"
                    min-height="300"
                    tile
                    class="d-flex justify-center align-center px-12 pb-10 pt-3 rounded"
                  >
                    <a-reputation-review
                      :review="review"
                      class="mx-10 mt-2 mb-6"
                    ></a-reputation-review>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-sheet>
          </v-col>
        </v-row>

        <a-reputation-no-reviews
          v-if="!any_reviews_answerable"
        ></a-reputation-no-reviews>
      </template>
      <template v-slot:loading>
        <v-row>
          <v-col cols="5" class="py-0">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-skeleton-loader
                  class="mx-auto"
                  height="170"
                  max-width="200"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto d-flex justify-center mt-2"
                  max-width="200"
                  type="heading"
                ></v-skeleton-loader>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-skeleton-loader
                  class="mt-4"
                  type="list-item-avatar"
                ></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="py-0"></v-col>
          <v-col cols="6" class="py-0">
            <v-skeleton-loader
              class="mt-10"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aIncrement from "@/components/aIncrement";
import aReputationReview from "@/components/reputation/aReputationReview";
import aReputationAnswerTimeGraph from "@/components/reputation/aReputationAnswerTimeGraph";
import aReputationNoReviews from "@/components/reputation/aReputationNoReviews.vue";
import aBaseComponent from "@/components/aBaseComponent";
import aLabel from "@/components/aLabel";

export default {
  name: "a-reputation-answer",
  components: {
    aCard,
    aBaseComponent,
    aReputationReview,
    aReputationAnswerTimeGraph,
    aReputationNoReviews,
    aLabel,
    aIncrement
  },
  props: {
    // kpi: {
    //   type: String,
    //   default: null
    // }
  },
  data: () => ({
    subscribed_modules: [
      "bi_report",
      "bi_reputation_answered_by_time",
      "bi_reputation_reviews"
    ],
    cycle_reviews: true
  }),
  methods: {},
  mounted() {},
  computed: {
    any_reviews_answerable() {
      return this.bi_reputation_answered_by_time.data["answerable"];
    },
    any_reviews_answered() {
      return this.bi_reputation_answered_by_time.data["answered"];
    },
    all_reviews_answered() {
      return (
        this.bi_reputation_answered_by_time.data["answered"] ==
        this.bi_reputation_answered_by_time.data["answerable"]
      );
    },
    not_answered_reviews() {
      return this.bi_reputation_reviews.filter(
        r => r.answerable && !r.answered
      );
    },
    getQuickAnswerColor() {
      var value = this.bi_reputation_answered_by_time.data.quick_answers_avg;
      var color = "green";
      if (value <= 50) {
        color = "red";
      } else if (value <= 80) {
        color = "orange";
      }
      return color;
    },
    title() {
      return this.ota ? this.ota + "_rating" : "veetal_rating";
    },
    ...mapState({
      bi_report: state => state.bi.report,
      bi_reputation_answered_by_time: state =>
        state.bi.reputation_answered_by_time,
      bi_reputation_reviews: state => state.bi.reputation_reviews,
      emotions: state => state.emotions,
      times_to_answer: state => state.times_to_answer
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
