<template>
  <v-row>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3"
        >
          general
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-day-general-kpi-columns
            :columns="columns_kpis"
            :dense="dense"
          ></a-rms-day-general-kpi-columns>
          <a-rms-day-general-kpi-row
            v-for="(row, index) in rows_kpis"
            :key="index"
            :columns="columns_kpis"
            :rowData="row"
            :rowIndex="index"
            :dense="dense"
          ></a-rms-day-general-kpi-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3"
        >
          Pickup
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-day-general-pickup-columns
            :columns="columns_pickup"
            :dense="dense"
          ></a-rms-day-general-pickup-columns>
          <a-rms-day-general-pickup-row
            v-for="(row, index) in rows_pickups"
            :key="index"
            :columns="columns_pickup"
            :rowData="row"
            :rowIndex="index"
            :dense="dense"
          ></a-rms-day-general-pickup-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aRmsDayGeneralKpiColumns from "@/components/rms/aRmsDayGeneralKpiColumns.vue";
import aRmsDayGeneralKpiRow from "@/components/rms/aRmsDayGeneralKpiRow.vue";
import aRmsDayGeneralPickupColumns from "@/components/rms/aRmsDayGeneralPickupColumns.vue";
import aRmsDayGeneralPickupRow from "@/components/rms/aRmsDayGeneralPickupRow.vue";

export default {
  name: "a-rms-day-general",
  components: {
    aRmsDayGeneralKpiColumns,
    aRmsDayGeneralKpiRow,
    aRmsDayGeneralPickupColumns,
    aRmsDayGeneralPickupRow
  },
  props: {
    date: String,
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rows_kpis: [],
    rows_pickups: []
  }),
  computed: {
    columns_kpis() {
      return [
        {
          title: "Occ",
          align: "left",
          sortable: true,
          value: "occupancy"
        },
        {
          title: "Rn",
          align: "left",
          sortable: true,
          value: "rn"
        },
        {
          title: "Adr",
          align: "left",
          sortable: true,
          value: "adr"
        },
        {
          title: "Revpar",
          align: "left",
          sortable: true,
          value: "revpar"
        },
        {
          title: "Rev",
          align: "left",
          sortable: true,
          value: "room_revenue"
        },
        {
          title: this.$t("intermediation"),
          align: "left",
          sortable: true,
          value: "intermediation"
        }
      ];
    },
    columns_pickup() {
      return [
        {
          title: "Occ",
          align: "left",
          sortable: true,
          value: "occupancy"
        },
        {
          title: "Rn",
          align: "left",
          sortable: true,
          value: "rn"
        },
        {
          title: "Adr",
          align: "left",
          sortable: true,
          value: "adr"
        },
        {
          title: "Revpar",
          align: "left",
          sortable: true,
          value: "revpar"
        },
        {
          title: "Rev",
          align: "left",
          sortable: true,
          value: "room_revenue"
        }
      ];
    },
    columns_events() {
      let ly_date = Vue.moment(this.date)
        .subtract(1, "year")
        .format("DD/MM/YYYY");
      return [
        {
          title: this.$t("today"),
          align: "left",
          sortable: true
        },
        {
          title: this.$t("ly") + " - " + ly_date,
          align: "left",
          sortable: true
        },
        {
          title: this.$t("lyn") + " - " + ly_date,
          align: "left",
          sortable: true
        }
      ];
    },
    item_day() {
      let item = {};
      let items = this.calendar_store.hotel_comparative_dates;
      if (Object.keys(items).length > 0 && items.hasOwnProperty(this.date)) {
        item = items[this.date];
      }
      return item;
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getKpisDay(date) {
      this.$store
        .dispatch("fetchRmsKpisDay", date)
        .then(response => {
          this.rows_kpis = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getKpisDayPickups(date) {
      this.$store
        .dispatch("fetchRmsKpisDayPickups", date)
        .then(response => {
          this.rows_pickups = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    async getHotelComparativeDates() {
      await this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: new Date(
            Vue.moment(this.date).format("YYYY"),
            0,
            1
          ).format("YYYY-MM-DD"),
          to_date: new Date(
            Vue.moment(this.date).format("YYYY"),
            11,
            31
          ).format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  },
  mounted() {
    this.getKpisDay(this.date);
    this.getKpisDayPickups(this.date);
    this.getHotelComparativeDates();
  }
};
</script>
