import { render, staticRenderFns } from "./aRmsYearSalesCharts.vue?vue&type=template&id=b1d7c6de&scoped=true"
import script from "./aRmsYearSalesCharts.vue?vue&type=script&lang=js"
export * from "./aRmsYearSalesCharts.vue?vue&type=script&lang=js"
import style0 from "./aRmsYearSalesCharts.vue?vue&type=style&index=0&id=b1d7c6de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1d7c6de",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VList,VListItem,VListItemContent,VListItemGroup,VRow})
