<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <div v-show="tab_view === 'table'">
        <div
          class="d-flex justify-end align-center mb-4 mt-4"
          style="width:100%"
        >
          <div
            class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
            style="height: 100%; position: relative;"
          >
            <a-label class="px-4">{{ $t("comparative") }}:</a-label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'relative' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'relative'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'relative' ? 'white' : 'black'"
                  >
                    mdi-percent-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("percentage") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'absolute' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'absolute'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'absolute' ? 'white' : 'black'"
                  >
                    mdi-counter
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("absolute") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'hide_vs' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'hide_vs'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'hide_vs' ? 'white' : 'black'"
                  >
                    mdi-circle-off-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("hide_vs") }}</span>
            </v-tooltip>
          </div>
          <div class="mr-0 ml-2">
            <div
              class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
              style="height: 100%; position: relative;"
            >
              <a-label class="px-4">Vista:</a-label>
              <template>
                <v-tooltip
                  bottom
                  v-for="(group, index) in year_sales_groups_names"
                  :key="'group_' + index"
                >
                  <template v-slot:activator="{ on, attrs }" v-if="group">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="group.isActive ? 'secondary' : 'transparent'"
                      :text-color="group.isActive ? 'white' : 'black'"
                      @click="group.isActive = !group.isActive"
                      class="mr-2 my-2"
                    >
                      {{ group.group }}
                    </v-chip>
                  </template>
                  <span>{{ $t(group.group) }}</span>
                </v-tooltip>
              </template>
            </div>
          </div>
        </div>
        <a-rms-year-sales-table
          :configYearSalesTable="configYearSalesTable"
          :monthsValues="monthsValues"
          :quartersValues="quartersValues"
          :yearValues="yearValues"
          :kpiMode="kpi_mode"
        >
        </a-rms-year-sales-table>
      </div>
      <a-rms-year-sales-charts v-show="tab_view === 'chart'" />
    </template>
    <template v-slot:loading>
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            v-for="i in 12"
            :key="i"
            type="card"
            height="70"
            class="elevation-0 mb-2"
          >
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import aRmsYearSalesTable from "@/components/rms/clicktotravel/aRmsYearSalesTable.vue";
import aRmsYearSalesCharts from "@/components/rms/clicktotravel/aRmsYearSalesCharts.vue";
import aLabel from "@/components/aLabel.vue";

const config_year_sales_table = {
  "": {
    columns: {
      date: {}
    },
    icon: null
  },
  otb: {
    columns: {
      occupancy: { kpi: "occupancy", block: "otb" },
      adr: { kpi: "adr", block: "otb" },
      revpar: { kpi: "revpar", block: "otb" },
      room_revenue: { kpi: "room_revenue", block: "otb" }
    }
  },
  stly: {
    columns: {
      occupancy: { kpi: "occupancy", block: "stly" },
      adr: { kpi: "adr", block: "stly" },
      revpar: { kpi: "revpar", block: "stly" },
      room_revenue: { kpi: "room_revenue", block: "stly" }
    }
  },
  ly: {
    columns: {
      occupancy: { kpi: "occupancy", block: "ly" },
      adr: { kpi: "adr", block: "ly" },
      revpar: { kpi: "revpar", block: "ly" },
      room_revenue: { kpi: "room_revenue", block: "ly" }
    }
  },
  bud: {
    columns: {
      occupancy: { kpi: "occupancy", block: "bud" },
      adr: { kpi: "adr", block: "bud" },
      revpar: { kpi: "revpar", block: "bud" },
      room_revenue: { kpi: "room_revenue", block: "bud" }
    }
  }
  /* forecast: {
    columns: {
      occupancy: { kpi: "occupancy", block: "forecast" },
      adr: { kpi: "adr", block: "forecast" },
      revpar: { kpi: "revpar", block: "forecast" },
      room_revenue: { kpi: "room_revenue", block: "forecast" }
    }
  } */
};

export default {
  name: "a-rms-year-sales",
  components: {
    aBaseComponent,
    aLabel,
    aRmsYearSalesTable,
    aRmsYearSalesCharts
  },
  props: {
    event_period: {
      type: Array,
      default: () => []
    },
    tab_view: {
      type: String,
      default: "table"
    }
  },
  data: () => ({
    title: "",
    subscribed_modules: [
      "rms_kpis_year_sales_months",
      "rms_kpis_year_sales_quarters",
      "rms_kpis_year_sales_year",
      "rms_year_sales_monthly_graph",
      "rms_year_sales_table",
      "rms_dashboard_annual_kpis"
    ],
    year_sales_groups_names: Object.keys(config_year_sales_table)
      .map(group => {
        return {
          group: group,
          icon: config_year_sales_table[group].icon,
          isActive: true
        };
      })
      .filter(g => g.group !== ""),
    kpi_mode: "relative"
  }),
  watch: {
    /* monthsValues() {
      this.monthsGraphData = {
        kpis: this.series_monthly,
        labels: this.labels_monthly
      };
    },
    quartersValues() {
      this.quartersGraphData = {
        kpis: this.series_quarterly,
        labels: this.labels_quarterly
      };
    } */
  },
  computed: {
    monthsValues() {
      return this.rms.kpis_year_sales_months;
    },
    quartersValues() {
      const quarters = {
        q1: this.rms.kpis_year_sales_q1,
        q2: this.rms.kpis_year_sales_q2,
        q3: this.rms.kpis_year_sales_q3,
        q4: this.rms.kpis_year_sales_q4
      };
      return { ...quarters };
    },
    yearValues() {
      return this.rms.kpis_year_sales_year;
    },
    configYearSalesTable() {
      return {
        "": config_year_sales_table[""],
        ...this.year_sales_groups_names.reduce((acc, cur) => {
          if (cur.isActive) {
            acc[cur.group] = config_year_sales_table[cur.group];
          }
          return acc;
        }, {})
      };
    },
    /* monthsGraphData: {
      get() {
        return this.rms.kpis_year_sales_months_graph;
      },
      set(value) {
        this.$store.commit("GET_DATASET", {
          dataset: "kpis_year_sales_months_graph",
          data: value
        });
      }
    },
    quartersGraphData: {
      get() {
        return this.rms.kpis_year_sales_quarters_graph;
      },
      set(value) {
        this.$store.commit("GET_DATASET", {
          dataset: "kpis_year_sales_quarters_graph",
          data: value
        });
      }
    }, */
    /* series_monthly() {
      return this.keys_monthly.reduce((acc, key) => {
        Object.keys(this.monthsValues[key].otb).forEach(kpi => {
          if (!acc[kpi]) {
            acc[kpi] = {
              otb: [],
              stly: []
            };
          }
          acc[kpi].otb.push(this.monthsValues[key].otb[kpi]);
          acc[kpi].stly.push(this.monthsValues[key].stly[kpi]);
        });
        return acc;
      }, {});
    },
    series_quarterly() {
      return this.labels_quarterly.reduce((acc, key) => {
        Object.keys(this.quartersValues[key].otb).forEach(kpi => {
          if (!acc[kpi]) {
            acc[kpi] = {
              otb: [],
              stly: []
            };
          }
          acc[kpi].otb.push(this.quartersValues[key].otb[kpi]);
          acc[kpi].stly.push(this.quartersValues[key].stly[kpi]);
        });
        return acc;
      }, {});
    }, */
    /* keys_monthly() {
      return Object.keys(this.monthsValues);
    },
    labels_monthly() {
      return this.keys_monthly.map(key =>
        Vue.moment(`${key}-01`).format("MMM")
      );
    }, */
    /* labels_quarterly() {
      return Object.keys(this.quartersValues);
    }, */
    ...mapState({
      rms: state => state.rms
      //available_rate_extractions: state => state.rate.available_rate_extractions
    })
    /* ...mapGetters({
      current_hotel: "current_hotel"
    }) */
  }
};
</script>
<style lang="scss">
.v-list--dense .v-list-item {
  min-height: 33px;
}
.v-date-picker-title__date {
  font-size: 16px;
}
.cols7 {
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  flex-basis: calc(100% / 7);
}
$height: 19px;
.a-rms-day {
  font-size: 11px;
  line-height: height;
  text-align: right;
  &:focus::before {
    background: white;
  }

  .a-rms-day-row-day {
    height: $height;
    font-weight: 600;
    text-transform: uppercase;
    color: #444444;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row-header {
    font-size: 11px;
    font-weight: 500;
    color: #666666;
    text-transform: uppercase;
    height: $height;
    overflow: hidden;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row {
    height: $height;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .a-rms-day-row-resume {
    height: $height;
    font-weight: 600;
    overflow: hidden;
  }
}
.day_details_mini {
  position: fixed;
  width: 90%;
  bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 20;
}
</style>
