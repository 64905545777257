<template>
  <v-autocomplete
    multiple
    deletable-chips
    :value="selectedRates"
    @input="updateModel"
    :items="rates"
    :item-text="getItemText"
    item-value="value"
    :rules="rules"
    rounded
    outlined
    :hide-details="hideDetails"
    :placeholder="placeholder"
    class="min-height-44"
    dense
  >
    <template #prepend>
      <v-icon size="24" v-if="prependIcon">{{ prependIcon }}</v-icon>
    </template>
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
        small
        v-if="data.index === 0"
      >
        <span v-if="data.item.rate_name">
          {{ data.item.rate_name }} ({{ data.item.room_name }})
        </span>
        <span v-else>
          {{ data.item.room_name }}
        </span>
      </v-chip>
      <span v-if="data.index === 1" class="grey--text text-caption">
        (+{{ selectedRates.length - 1 }} {{ $t("more") }})
      </span>
    </template>
    <template v-slot:item="data">
      <template v-if="data.item.isRoom">
        <v-list-item-icon v-if="type === 'room'">
          <v-icon>mdi-bed</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <span>{{ data.item.room_name }}</span>
        </v-list-item-content>
      </template>
      <template v-else>
        {{ data.item.rate_name }}
        <span class="font-weight-regular pl-1" v-if="data.item.rate_description"
          >( {{ data.item.rate_description }} )</span
        >
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    selectedRates: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    rates: {
      type: Array,
      default: () => [{}]
    },
    prependIcon: {
      type: String,
      default: "mdi-ticket"
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function() {
        return this.$i18n.t("select_rates_or_rooms");
      }
    },
    type: {
      type: String,
      default: "rate"
    }
  },
  model: {
    prop: "selectedRates",
    event: "input"
  },
  methods: {
    remove(item) {
      const index = this.selectedRates.indexOf(item.value);
      if (index >= 0) this.selectedRates.splice(index, 1);
    },
    updateModel(e) {
      this.$emit("input", e);
    }
  },
  computed: {
    rules() {
      return this.required
        ? [v => v.length > 0 || this.$i18n.t("at_least_one_option")]
        : [];
    },
    getItemText() {
      return this.type === "rate" ? "rate_name" : "room_name";
    }
  }
};
</script>
<style lang="scss" scoped>
// .h-32 {
//   ::v-deep .v-select__selections {
//     min-height: 32px !important;
//   }
// }
// .transparent {
//   ::v-deep div[role="combobox"] {
//     background-color: transparent !important;
//   }
// }
// .transparent {
//   ::v-deep .theme--dark.v-text-field--solo {
//     margin-top:200px;
//     .v-input__control {
//       .v-input__slot {
//         background-color: transparent;
//       }
//     }
//   }
// }

// .min-height-44 {
//   ::v-deep div[role="combobox"] {
//     min-height: 44px !important;
//   }
//   ::v-deep .v-input__append-inner {
//     margin-top: 10px !important;
//   }
// }
</style>
